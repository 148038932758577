<template>
  <v-container
    id="user-auth"
    class="fill-height primary"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card
          class="elevation-12"
          :loading="formLoading"
          tile
        >
          <v-toolbar
            color="secondary"
            flat
            dark
          >
            <v-img
              class="mr-3"
              src="@/assets/monigle-logo.svg"
              max-width="50"
              contain
            />
            <v-divider vertical />
            <v-toolbar-title class="ml-3">
              Dev Ops - Login
            </v-toolbar-title>
          </v-toolbar>
          <v-divider />
          <v-card-text>
            <v-form
              ref="form"
              v-model="formValid"
              lazy-validation
            >
              <v-text-field
                v-model="formEmail"
                type="email"
                label="Email Address"
                :rules="$options.emailRules"
              />
              <v-text-field
                v-model="formPassword"
                type="password"
                label="Password"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              id="login-submit"
              color="primary"
              large
              :disabled="
                loginDisabled
              "
              @click.exact="handleSubmit"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-snackbar
          v-model="formError"
          top
          color="error"
        >
          {{ formErrorMessage }}
          <v-btn
            dark
            text
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import { userLogin } from '../services'
import { appLocalStorage } from '../utils'
import { recaptcha } from '../plugins'
export default {
  name: 'user-auth',
  data: () => ({
    formValid: true,
    formErrorMessage: '',
    formPassword: '',
    formEmail: '',
    formError: false,
    formLoading: false,
    jwt: '',
    jwt_refresh_token: ''
  }),
  computed: {
    ...mapState({
      user: state => state.User.data,
      loading: state => state.User.loading,
      error: state => state.User.error
    }),
    loginDisabled () {
      return (
        Boolean(!this.formPassword.length) ||
        Boolean(!this.formEmail.length) ||
        !this.formValid
      )
    },
    jwtData () {
      // JWT's are two base64-encoded JSON objects and a trailing signature
      // joined by periods. The middle section is the data payload.
      if (this.jwt) return JSON.parse(atob(this.jwt.split('.')[1]))
      return {}
    }
  },
  emailRules: [
    v => !!v || 'E-mail is required',
    v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ],
  watch: {
    /**
     * when user data received and stored
     * replace route to avoid making entry
     * in browser history.
     */
    user: {
      immediate: true,
      handler: function (value) {
        if (value) {
          //appLocalStorage.setItem('qa-env', value)
          //console.log(value.role);
          if(value.role == "admin") {
            this.$router.replace('/deployments').catch(() => {})
          }else{
            this.$router.replace('/database-export').catch(() => {})
          }
        }
      }
    }
  },
  /**
   * Current Dev Ops Engineers
   *
   * @todo make this a table in database
   *
   */
  engineers: [
    {
      text: 'Nick Fisher',
      value: 'qa1'
    },
    {
      text: 'Avery Chen',
      value: 'qa2'
    },
    {
      text: 'Anson Smith',
      value: 'qa3'
    },
    {
      text: 'Grace Lee',
      value: 'qa4'
    },
    {
      text: 'Nick Fisher',
      value: 'qa5'
    },
    {
      text: 'Nick Fisher',
      value: 'qa6'
    },    
    {
      text: 'Nick Fisher',
      value: 'qa7'
    }
  ],
  methods: {
    async handleSubmit () {
      this.formLoading = true
      /**
       * see frontend/src/plugins/vue-recaptcha.js
       * for configuration
       */
      // await recaptcha()

      try {
        const result = await userLogin({
          username: this.formEmail,
          password: this.formPassword
        })
        
        this.jwt = result.accessToken;
        this.jwt_refresh_token = result.refreshToken;
        const user = this.jwtData;
      
       
        user.JWT_TOKEN = this.jwt;
        user.JWT_REFRESH_TOKEN = this.jwt_refresh_token;
       
        
        this.$store.dispatch('User/setProperty', {
          collection: 'data',
          data: user
        })
      } catch (error) {
        this.formError = true
        // this.formErrorMessage = error.response.data.message
        this.formLoading = false
        this.formPassword = ''
      } finally {
        this.formPassword = ''
        this.formLoading = false
      }
    }
  }
}
</script>