/**
 * Google Recaptcha
 *
 * @reference https://www.npmjs.com/package/vue-recaptcha-v3
 *
 */

import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, { siteKey: '6LcahNwfAAAAAKX_CAVOgCExzWaQwtF5prkBDtmN' })

export const recaptcha = async () => {
  // (optional) Wait until recaptcha has been loaded.
  await this.$recaptchaLoaded()

  // Execute reCAPTCHA with action "login".
  const token = await this.$recaptcha('login')

  // Do stuff with the received token.
  /* eslint-disable-next-line no-console */
  console.log(token)
}
